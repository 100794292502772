import React from 'react';
import FXRateSimulator from './FXRateSimulator';

function App() {
  return (
    <div className="App">
      <FXRateSimulator />
    </div>
  );
}

export default App;
