import React, { useState, useEffect, useCallback } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { RefreshCw } from 'lucide-react';

const FXRateSimulator = () => {
  // 環境変数からデフォルト値を取得
  const DEFAULT_LOT_SIZE = parseInt(process.env.REACT_APP_DEFAULT_LOT_SIZE, 10) || 50;
  const DEFAULT_STOP_LOSS = parseFloat(process.env.REACT_APP_DEFAULT_SL) || 0.5;
  const DEFAULT_TAKE_PROFIT = parseFloat(process.env.REACT_APP_DEFAULT_TP) || 0.5;
  const DEFAULT_LEVERAGE = parseInt(process.env.REACT_APP_DEFAULT_LEVERAGE, 10) || 25;
  const SPECIFIED_SECONDS = parseInt(process.env.REACT_APP_SPECIFIED_SECONDS, 10) || 30;
  const INITIAL_RATE = parseFloat(process.env.REACT_APP_INITIAL_RATE) || 150.0;
  const MAX_ORDER_HISTORY = parseInt(process.env.REACT_APP_MAX_ORDER_HISTORY, 10) || 200;

  const [rate, setRate] = useState(INITIAL_RATE);
  const [orders, setOrders] = useState([]);
  const [newOrderQuantity, setNewOrderQuantity] = useState(DEFAULT_LOT_SIZE);
  const [newOrderPrice, setNewOrderPrice] = useState('');
  const [newOrderStopLoss, setNewOrderStopLoss] = useState('');
  const [newOrderTakeProfit, setNewOrderTakeProfit] = useState('');
  const [rateHistory, setRateHistory] = useState([{ time: 0, rate: INITIAL_RATE }]);
  const [orderType, setOrderType] = useState('market');
  const [isPaused, setIsPaused] = useState(false);

  const updateRate = useCallback(() => {
    if (!isPaused) {
      setRate((prevRate) => {
        const buyPressure = orders
          .filter((order) => order.type === 'buy' && order.status === 'active')
          .reduce((sum, order) => sum + order.quantity, 0);
        const sellPressure = orders
          .filter((order) => order.type === 'sell' && order.status === 'active')
          .reduce((sum, order) => sum + order.quantity, 0);
        const change = (buyPressure - sellPressure) * 0.01;
        const newRate = Math.max(0, prevRate + change);
        setRateHistory((prev) =>
          [...prev, { time: prev.length, rate: newRate }].slice(-60)
        );
        return newRate;
      });
    }
  }, [orders, isPaused]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isPaused) {
        updateRate();
        updateOrders();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [updateRate, isPaused]);

  const handleOrders = (type) => {
    const price =
      orderType === 'market' ? rate : parseFloat(newOrderPrice) || rate;
    const stopLoss =
      newOrderStopLoss !== ''
        ? parseFloat(newOrderStopLoss)
        : type === 'buy'
        ? price - DEFAULT_STOP_LOSS
        : price + DEFAULT_STOP_LOSS;
    const takeProfit =
      newOrderTakeProfit !== ''
        ? parseFloat(newOrderTakeProfit)
        : type === 'buy'
        ? price + DEFAULT_TAKE_PROFIT
        : price - DEFAULT_TAKE_PROFIT;
    const newOrder = {
      type,
      price,
      stopLoss,
      takeProfit,
      status: orderType === 'market' ? 'active' : 'pending',
      quantity: newOrderQuantity,
      id: Date.now() + Math.random(),
      remainingTime: SPECIFIED_SECONDS,
      orderType,
    };

    setOrders((prev) => {
      const newOrders = [...prev, newOrder];
      if (newOrders.length > MAX_ORDER_HISTORY) {
        newOrders.shift();
      }
      return newOrders;
    });
  };

  const updateOrders = () => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        if (order.status === 'pending') {
          if (!isPaused) {
            order = { ...order, remainingTime: order.remainingTime - 1 };
          }
          if (order.remainingTime <= 0) {
            return { ...order, status: 'canceled' };
          } else {
            if (
              (order.type === 'buy' && rate <= order.price) ||
              (order.type === 'sell' && rate >= order.price)
            ) {
              return { ...order, status: 'active' };
            }
          }
        } else if (order.status === 'active') {
          if (
            (order.type === 'buy' &&
              (rate <= order.stopLoss || rate >= order.takeProfit)) ||
            (order.type === 'sell' &&
              (rate >= order.stopLoss || rate <= order.takeProfit))
          ) {
            return { ...order, status: 'closed', closeRate: rate };
          }
        }
        return order;
      })
    );
  };

  const resetSimulation = () => {
    setRate(INITIAL_RATE);
    setOrders([]);
    setRateHistory([{ time: 0, rate: INITIAL_RATE }]);
    setOrderType('market');
    setIsPaused(false);
  };

  const settleOrder = (orderId) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId && order.status === 'active'
          ? { ...order, status: 'closed', closeRate: rate }
          : order
      )
    );
  };

  const renderOrderList = () => {
    // 未決済注文
    const unsettledOrders = orders
      .filter((order) => order.status === 'pending' || order.status === 'active')
      .sort((a, b) => b.id - a.id);

    // 決済済み注文
    const settledOrders = orders
      .filter((order) => order.status === 'closed' || order.status === 'canceled')
      .sort((a, b) => b.id - a.id);

    return (
      <div>
        <h3 className="font-semibold mb-2">未決済注文一覧</h3>
        <div className="overflow-y-auto max-h-80 mb-4">
          <table className="min-w-full text-sm">
            <thead>
              <tr>
                <th>注文ID</th>
                <th>タイプ</th>
                <th>価格</th>
                <th>数量</th>
                <th>SL</th>
                <th>TP</th>
                <th>注文種別</th>
                <th>損益</th>
                <th>残り時間</th>
                <th>ステータス</th>
                <th>決済</th>
              </tr>
            </thead>
            <tbody>
              {unsettledOrders.slice(0, 20).map((order) => {
                let profitLoss = 0;
                if (order.status === 'active') {
                  if (order.type === 'buy') {
                    profitLoss =
                      (rate - order.price) * order.quantity * DEFAULT_LEVERAGE;
                  } else {
                    profitLoss =
                      (order.price - rate) * order.quantity * DEFAULT_LEVERAGE;
                  }
                }
                return (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.type}</td>
                    <td>{order.price.toFixed(2)}</td>
                    <td>{order.quantity}</td>
                    <td>{order.stopLoss.toFixed(2)}</td>
                    <td>{order.takeProfit.toFixed(2)}</td>
                    <td>{order.orderType === 'limit' ? '指値' : '成行'}</td>
                    <td>{profitLoss.toFixed(2)}</td>
                    <td>{order.remainingTime}</td>
                    <td>{order.status}</td>
                    <td>
                      {order.status === 'active' && (
                        <button
                          onClick={() => settleOrder(order.id)}
                          className="px-2 py-1 bg-green-500 text-white rounded-md"
                        >
                          決済
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3 className="font-semibold mb-2">決済済み注文一覧</h3>
        <div className="overflow-y-auto max-h-80 mb-4">
          <table className="min-w-full text-sm">
            <thead>
              <tr>
                <th>注文ID</th>
                <th>タイプ</th>
                <th>価格</th>
                <th>数量</th>
                <th>SL</th>
                <th>TP</th>
                <th>注文種別</th>
                <th>確定損益</th>
                <th>ステータス</th>
              </tr>
            </thead>
            <tbody>
              {settledOrders.slice(0, 20).map((order) => {
                let confirmedPL = 0;
                if (order.status === 'closed') {
                  if (order.type === 'buy') {
                    confirmedPL =
                      (order.closeRate - order.price) *
                      order.quantity *
                      DEFAULT_LEVERAGE;
                  } else {
                    confirmedPL =
                      (order.price - order.closeRate) *
                      order.quantity *
                      DEFAULT_LEVERAGE;
                  }
                }
                return (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.type}</td>
                    <td>{order.price.toFixed(2)}</td>
                    <td>{order.quantity}</td>
                    <td>{order.stopLoss.toFixed(2)}</td>
                    <td>{order.takeProfit.toFixed(2)}</td>
                    <td>{order.orderType === 'limit' ? '指値' : '成行'}</td>
                    <td>{confirmedPL.toFixed(2)}</td>
                    <td>{order.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const aggregateOrders = (orders) => {
    return orders.reduce((acc, order) => {
      const key = `${order.type}-${order.price.toFixed(2)}-${order.status}`;
      if (!acc[key]) {
        acc[key] = { ...order, quantity: 0 };
      }
      acc[key].quantity += order.quantity;
      return acc;
    }, {});
  };

  const renderOrderBook = () => {
    const activeOrders = orders.filter((order) => order.status !== 'closed');
    const aggregatedOrders = Object.values(aggregateOrders(activeOrders));
    const sortedOrders = aggregatedOrders.sort((a, b) => b.price - a.price);

    const buyOrders = sortedOrders.filter((order) => order.type === 'buy');
    const sellOrders = sortedOrders.filter((order) => order.type === 'sell');

    const maxQuantity = Math.max(
      ...aggregatedOrders.map((order) => order.quantity),
      1
    );
    const quantitySteps = Math.ceil(maxQuantity / 10);

    return (
      <div className="flex justify-between items-center h-60 bg-gray-100 rounded-lg p-2 mb-4">
        <div className="w-5/12 h-full flex flex-col justify-end">
          {buyOrders.map((order) => (
            <div key={order.id} className="flex justify-between items-center">
              <div className="w-1/4">{order.price.toFixed(2)}</div>
              <div className="w-3/4 flex">
                {Array.from({
                  length: Math.ceil(order.quantity / 10),
                }).map((_, index) => (
                  <div
                    key={index}
                    className="h-4 bg-blue-500 mr-px"
                    style={{ width: `${100 / quantitySteps}%` }}
                  ></div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="w-2/12 text-center">
          <div className="font-bold">{rate.toFixed(2)}</div>
        </div>
        <div className="w-5/12 h-full flex flex-col justify-end">
          {sellOrders.map((order) => (
            <div key={order.id} className="flex justify-between items-center">
              <div className="w-3/4 flex justify-end">
                {Array.from({
                  length: Math.ceil(order.quantity / 10),
                }).map((_, index) => (
                  <div
                    key={index}
                    className="h-4 bg-red-500 ml-px"
                    style={{ width: `${100 / quantitySteps}%` }}
                  ></div>
                ))}
              </div>
              <div className="w-1/4 text-right">{order.price.toFixed(2)}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPositions = () => {
    const positions = orders
      .filter((order) => order.status === 'active')
      .reduce(
        (acc, order) => {
          if (!acc[order.type]) {
            acc[order.type] = 0;
          }
          acc[order.type] += order.quantity;
          return acc;
        },
        { buy: 0, sell: 0 }
      );

    return (
      <div className="mb-4">
        <h3 className="font-semibold">ポジション状況：</h3>
        <div className="text-sm">
          <p>買いポジション: {positions.buy || 0}</p>
          <p>売りポジション: {positions.sell || 0}</p>
        </div>
      </div>
    );
  };

  const renderPositionBarChart = () => {
    const positions = orders
      .filter((order) => order.status === 'active')
      .reduce(
        (acc, order) => {
          acc[order.type] += order.quantity;
          return acc;
        },
        { buy: 0, sell: 0 }
      );

    const data = [
      { name: '買い', quantity: positions.buy },
      { name: '売り', quantity: positions.sell },
    ];

    return (
      <div className="h-60 w-full mb-4">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Bar dataKey="quantity" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className="p-4 max-w-3xl mx-auto bg-white rounded-xl shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">FXレートシミュレーター</h2>

      <div className="mb-4 flex justify-between items-center">
        <div className="w-1/2 pr-2">
          <label className="block text-sm font-medium text-gray-700">現在のレート</label>
          <input
            type="number"
            value={rate.toFixed(2)}
            readOnly
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100"
          />
        </div>
        <div className="w-1/2 pl-2">
          <label className="block text-sm font-medium text-gray-700">注文タイプ</label>
          <select
            value={orderType}
            onChange={(e) => setOrderType(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          >
            <option value="market">成行注文</option>
            <option value="limit">指値注文</option>
          </select>
        </div>
      </div>

      {orderType === 'limit' && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">指値価格</label>
          <input
            type="number"
            value={newOrderPrice}
            onChange={(e) => setNewOrderPrice(e.target.value)}
            placeholder="指値価格を入力"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>
      )}

      <div className="mb-4 flex justify-between items-center">
        <div className="w-1/2 pr-2">
          <label className="block text-sm font-medium text-gray-700">注文量（ロット数）</label>
          <input
            type="number"
            value={newOrderQuantity}
            onChange={(e) =>
              setNewOrderQuantity(Math.max(1, parseInt(e.target.value, 10) || 1))
            }
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>
        <div className="w-1/2 pl-2">
          <label className="block text-sm font-medium text-gray-700">ストップロス (SL)</label>
          <input
            type="number"
            value={newOrderStopLoss}
            onChange={(e) => setNewOrderStopLoss(e.target.value)}
            placeholder={`デフォルトは${DEFAULT_STOP_LOSS}`}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">テイクプロフィット (TP)</label>
        <input
          type="number"
          value={newOrderTakeProfit}
          onChange={(e) => setNewOrderTakeProfit(e.target.value)}
          placeholder={`デフォルトは${DEFAULT_TAKE_PROFIT}`}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        />
      </div>

      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => handleOrders('buy')}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          買い注文
        </button>
        <button
          onClick={() => handleOrders('sell')}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          売り注文
        </button>
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
        >
          {isPaused ? '再開' : '一時停止'}
        </button>
        <button
          onClick={resetSimulation}
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 flex items-center"
        >
          <RefreshCw className="mr-2" size={16} />
          リセット
        </button>
      </div>

      {renderOrderList()}

      {renderOrderBook()}

      {renderPositions()}

      {renderPositionBarChart()}

      <div className="h-60 w-full mb-4">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={rateHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis domain={['auto', 'auto']} />
            <Tooltip />
            <Line type="monotone" dataKey="rate" stroke="#8884d8" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <p className="mt-4 text-sm text-gray-600">
        買い注文と売り注文のバランスによってFXレートが変動します。
        成行注文は即時に実行され、指値注文は指定した価格に到達したときに実行されます。
        ストップロス (SL) またはテイクプロフィット (TP) に到達すると、注文は自動的に解消されます。
        SLのデフォルトは{DEFAULT_STOP_LOSS}、TPのデフォルトは{DEFAULT_TAKE_PROFIT}です。
        レートは1秒ごとに更新され、買い注文が多いと上昇し、売り注文が多いと下落します。
        グラフは過去60秒間のレート推移を表示しています。
        決済ボタンを押すと、特定の注文を決済することができます。
        リセットボタンを押すと、すべての取引がキャンセルされ初期状態に戻ります。
        一時停止中でも注文や決済が可能で、一時停止解除後にすべて反映されます。
      </p>
    </div>
  );
};

export default FXRateSimulator;
